<template>
  <CCard body-wrapper>
    <CRow
      align-vertical="center"
      class="mb-3"
    >
      <CCol col="auto">
        <h1>
          Операции
        </h1>
      </CCol>
      <CCol col="auto">
        <template v-if="filtersVisible">
          <template v-if="filtersSet">
            <CButton
              color="warning"
              @click="filtersVisible = !filtersVisible"
            >
              Фильтр
            </CButton>
          </template>
          <template v-else>
            <CButton
              color="dark"
              @click="filtersVisible = !filtersVisible"
            >
              Фильтр
            </CButton>
          </template>
        </template>
        <template v-else>
          <template v-if="filtersSet">
            <CButton
              color="warning"
              variant="outline"
              @click="filtersVisible = !filtersVisible"
            >
              Фильтр
            </CButton>
          </template>
          <template v-else>
            <CButton
              color="dark"
              variant="outline"
              @click="filtersVisible = !filtersVisible"
            >
              Фильтр
            </CButton>
          </template>
        </template>
      </CCol>
      <template v-if="loadingOrders">
        <CCol col="auto">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          <span class="sr-only">"Пожалуйста, подождите...</span>
        </CCol>
      </template>
      <template v-else>
        <CCol col="auto">
          Количество: <span
            class="font-weight-bold"
          >{{ getLocalizedNumber(total.orders) }}</span>
        </CCol>
        <CCol col="auto">
          Оплаты: <span
            class="font-weight-bold"
          >{{ getLocalizedAmount(total.payment / 100) }}</span>
        </CCol>
        <CCol col="auto">
          Возвраты: <span
          class="font-weight-bold"
        >{{ getLocalizedAmount(total.refund / 100) }}</span>
        </CCol>
        <CCol col="auto">
          Итого: <span
          class="font-weight-bold"
        >{{ getLocalizedAmount(total.amount / 100) }}</span>
        </CCol>
      </template>
    </CRow>
    <CForm
      v-show="filtersVisible"
      class="filters mb-3 p-3"
      @submit.prevent="find"
    >
      <CRow align-vertical="start">
        <CCol
          xs="12"
          xl="3"
        >
          <div class="filters__filter">
            <CButton
              class="filters__filter-button"
              color="primary"
              type="submit"
            >
              <template v-if="loadingOrders">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                <span class="sr-only">Пожалуйста, подождите...</span>
              </template>
              <template v-else>
                Найти
              </template>
            </CButton>
            <CButton
              class="filters__filter-button ml-2"
              color="secondary"
              @click="clearFilters"
            >
              Очистить
            </CButton>
          </div>
        </CCol>
        <CCol
          xs="12"
          xl="9"
        >
          <CRow class="mb-1">
            <CCol
              xs="12"
              xl="3"
            >
              <div
                class="filters__filter"
                :class="{ 'filters__filter--active': filters.external_number !== '' }"
              >
                <CInput
                  label="Номер заказа или операции"
                  :value.sync="filters.external_number"
                />
              </div>
            </CCol>
            <CCol
              xs="12"
              xl="3"
            >
              <div
                class="filters__filter"
                :class="{ 'filters__filter--active': filters.transaction_type !== null|undefined && filters.transaction_type !== '' }"
              >
                <CSelect
                  label="Тип операции"
                  :options="[ { value: null, label: '' },  { value: 'payment', label: 'Оплата' }, { value: 'refund', label: 'Возврат' } ]"
                  :value.sync="filters.transaction_type"
                />
              </div>
            </CCol>
            <CCol
              xs="12"
              xl="3"
            >
              <div
                class="filters__filter"
                :class="{ 'filters__filter--active': filters.status !== null|undefined && filters.status !== '' }"
              >
                <CSelect
                  label="Статус"
                  :options="options"
                  :value.sync="filters.status"
                />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol
              xs="12"
              xl="6"
            >
              <div
                class="filters__filter"
                :class="{
              'filters__filter--active':
                filters.created_at_from !== null|undefined && filters.created_at_from !== '' || filters.created_at_to !== null|undefined && filters.created_at_to !== ''
            }"
              >
                <CRow>
                  <CCol col="12">
                    <label for="createdAtFrom">Дата операции</label>
                  </CCol>
                </CRow>
                <CRow
                  align-vertical="center"
                  :gutters="false"
                >
                  <CCol>
                    <v-date-picker
                      v-model="filters.created_at_from"
                      :attributes="[{ dot: true, dates: new Date(), key: 'today' }]"
                      locale="ru"
                      @input="
                    filters.created_at_from =
                      $event
                        ? locale.format($event, 'YYYY-MM-DD')
                        : null
                  "
                    >
                      <template #default="{ inputValue, togglePopover }">
                        <CRow
                          align-vertical="center"
                          :gutters="false"
                        >
                          <CCol>
                            <CInput
                              id="createdAtFrom"
                              :lazy="800"
                              :readonly="true"
                              :value="inputValue"
                              @click="togglePopover({ placement: 'bottom-start' })"
                              @update:value="filters.created_at_from = formatDate($event)"
                            >
                              <template #prepend-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </CCol>
                          <CCol
                            v-if="filters.created_at_from !== ''"
                            col="1"
                            class="text-center"
                          >
                            <CLink @click="filters.created_at_from = ''">
                              <CIcon name="cil-x" />
                            </CLink>
                          </CCol>
                        </CRow>
                      </template>
                    </v-date-picker>
                  </CCol>
                  <CCol
                    col="auto"
                    class="pl-1 pr-1 text-center"
                  >
                    &mdash;
                  </CCol>
                  <CCol>
                    <v-date-picker
                      v-model="filters.created_at_to"
                      :attributes="[{ dot: true, dates: new Date(), key: 'today' }]"
                      locale="ru"
                      @input="
                    filters.created_at_to =
                      $event
                        ? locale.format($event, 'YYYY-MM-DD')
                        : null
                  "
                    >
                      <template #default="{ inputValue, togglePopover }">
                        <CRow
                          align-vertical="center"
                          :gutters="false"
                        >
                          <CCol>
                            <CInput
                              id="createdAtTo"
                              :lazy="800"
                              :readonly="true"
                              :value="inputValue"
                              @click="togglePopover({ placement: 'bottom-start' })"
                              @update:value="filters.created_at_to = formatDate($event)"
                            >
                              <template #prepend-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </CCol>
                          <CCol
                            v-if="filters.created_at_to !== ''"
                            col="1"
                            class="text-center"
                          >
                            <CLink @click="filters.created_at_to = ''">
                              <CIcon name="cil-x" />
                            </CLink>
                          </CCol>
                        </CRow>
                      </template>
                    </v-date-picker>
                  </CCol>
                </CRow>
              </div>
            </CCol>
            <CCol
              xs="12"
              xl="3"
            >
              <div
                class="filters__filter"
                :class="{ 'filters__filter--active': filters.success_only !== null|undefined && filters.success_only !== '' }"
              >
                <CSelect
                  label="Только успешные"
                  :options="[ { value: null, label: '' },  { value: 'yes', label: 'Да' } ]"
                  :value.sync="filters.success_only"
                />
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CForm>
    <CDataTable
      class="mt-5"
      :fields="tableFields"
      :header="true"
      :hover="true"
      :items="orders"
      :loading="loadingOrders"
      :striped="true"
    >
      <template #Amount="{ item }">
        <td class="align-middle text-right">
          {{ getLocalizedAmount(item.Amount / 100) }}
        </td>
      </template>
      <template #orderType="{ item }">
        <td :class="getColoredClass('align-middle text-center', item.TransactionType)">
          {{ item.TransactionType === 'payment' ? 'Оплата' : 'Возврат' }}
        </td>
      </template>
      <template #CreatedAt="{ item }">
        <td class="align-middle text-center">
          {{ getLocalizedDate(item.CreatedAt) }} {{ getLocalizedTime(item.CreatedAt) }}
        </td>
      </template>
      <template #loading>
        <CElementCover
          :opacity="0.8"
        >
          <CSpinner
            class="custom-spinner"
            color="info"
          />
        </CElementCover>
      </template>
      <template #no-items-view>
        <div class="text-center">
          Отсутствуют операции
        </div>
      </template>
      <template #ExternalNumber="{ item }">
        <td class="align-middle text-center">
          {{ item.ExternalNumber }}
        </td>
      </template>
      <template #Status="{ item }">
        <td class="align-middle text-center">
          <template>
            <span :class="getColoredStatusClass('badge text-color-white', item.Status)">
              {{ getStatusName(item.Status) }}
            </span>
          </template>
        </td>
      </template>
      <template #PaymentId="{ item }">
        <template v-if="item.PaymentId !== null">
          <td class="align-middle text-center">
            {{ item.PaymentId }}
          </td>
        </template>
        <template v-else>
          <td></td>
        </template>
      </template>
      <template #options="{ item }">
        <template v-if="checkReturnBtn(item)">
          <td class="align-middle text-center">
            <CLink @click="showRefund(item)">Сделать возврат</CLink>

<!--            <CDropdown
              color=""
              toggler-text="..."
              class="m-1"
              :caret=false
              placement="top-start"
            >
              <CDropdownItem
                @click="showRefund(item)"
              >Возврат</CDropdownItem>
            </CDropdown>-->
          </td>
        </template>
        <template v-else-if="item.ManagerIdentifier !== undefined && item.ManagerIdentifier.length > 0">
          <td class="align-middle text-center">Инициатор {{ item.ManagerIdentifier }}</td>
        </template>
        <template v-else>
          <td></td>
        </template>
      </template>
    </CDataTable>
    <CPagination
      v-if="pagesCount > 1"
      :active-page.sync="currentPage"
      :pages="pagesCount"
      align="start"
      @update:activePage="getOrders"
    />
    <div class="export-request-completed-alert">
      <CAlert
        color="danger"
        closeButton
        :show.sync="dangerAlertCounter"
        class="position-sticky"
        @close="successAlert"
      >
        {{dangerAlertContent}}
        <CProgress
          :max="10"
          :value="dangerAlertCounter"
          height="3px"
          color="danger"
          hidden
        />
      </CAlert>
      <CAlert
        color="success"
        closeButton
        :show.sync="successAlertCounter"
        class="position-sticky"
      >
        {{successAlertContent}}
        <CProgress
          :max="10"
          :value="successAlertCounter"
          height="3px"
          color="success"
          hidden
        />
      </CAlert>
    </div>
    <template>
      <div>
        <CModal
          :title="'Введите сумму возврата по заказу №' + needRefundItem.OrderNumber"
          :show.sync="showRefundModal"
          :backdrop="false"
          :centered="true"
          :closeOnBackdrop="false"
        >
            <CInput
              label="Сумма возврата"
              :value.sync="needRefundItem.Amount"
              :disabled="isProcessingRefund"
              :isValid="!isValidationRefundError"
              @change.capture="validateRefundAmount"
              @blur="validateRefundAmount"
            />
            <template v-slot:footer>
              <CButton
                class="btn btn-primary"
                color="primary"
                @click="createRefund"
                :disabled="isProcessingRefund || isValidationRefundError"
              >
                <template v-if="isProcessingRefund">
                  <div class="loader">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                    <span class="sr-only">Пожалуйста, подождите...</span></div>
                </template>
                <template v-else>Сделать возврат</template>
              </CButton>
              <CButton
                class="btn"
                @click="showRefundModal=false"
                :disabled="isProcessingRefund"
              >
                <template v-if="isProcessingRefund">
                  <div class="loader">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                    <span class="sr-only">Пожалуйста, подождите...</span></div>
                </template>
                <template v-else>Отменить</template>
              </CButton>
            </template>
        </CModal>
      </div>
    </template>
  </CCard>

</template>

<script>
import Swal from 'sweetalert2';
import AmountMixins from '../../../mixins/AmountMixins';
import DateTimeMixins from '../../../mixins/DateTimeMixins';
import ListMixins from '../../../mixins/ListMixins';
import NumberMixins from '../../../mixins/NumberMixins';
import urls from '../../../router/urls';
// import * as sweetalert2 from "sweetalert2";
// import {validate} from "@vue/cli-service/lib/options";
import {isNumber} from "v-calendar/src/utils/_";
//import show from "vue/src/platforms/web/runtime/directives/show";

export default {
  name: 'List',
  computed: {
    show() {
      return show
    }
  },

  mixins: [
    AmountMixins,
    DateTimeMixins,
    ListMixins,
    NumberMixins,
  ],

  data() {
    return {
      actionIsBeingPerformed: false,
      filters: {},
      loadingOrders: false,
      orders: [],
      needRefundItem: {
        OrderNumber: '',
        PaymentId: '',
        Amount: 0,
      },
      isValidationRefundError: false,
      initialMaxRefundAmount: 0,
      showRefundModal: false,
      dangerAlertCounter: 0,
      successAlertCounter: 0,
      dangerAlertContent: '',
      successAlertContent: '',
      performAndActionWithAll: false,
      isProcessingRefund: false,
      options: [
        { value: null, label: '' },
        { value: 'Wait', label: 'В ожидании оплаты' },
        { value: 'QrTokenCreated', label: 'Новая оплата' },
        { value: 'Processed', label: 'Успешная оплата' },
        { value: 'Error', label: 'Ошибка оплаты' },
        { value: 'RefundProcessed', label: 'Возврат проведён' },
      ],
      tableFields: [
        {
          _classes: ['align-middle', 'text-center'],
          key: 'ExternalNumber',
          label: '№ заказа',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'orderType',
          label: 'Тип операции',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'PaymentId',
          label: 'Номер операции',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'CreatedAt',
          label: 'Время операции',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'Status',
          label: 'Статус',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'Amount',
          label: 'Сумма, ₸',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'options',
          label: 'Возврат',
        },
      ],
      total: {
        payment: 0,
        refund: 0,
        amount: 0,
        orders: 0,
      },
    };
  },

  created() {
    this.initFilters();
    this.setFiltersFromUrl();

    this.getOrders();
  },
  methods: {
    async clearFilters() {
      this.initFilters(true);

      await this.find();
    },
    async find() {
      this.currentPage = 1;

      await this.getOrders();
    },
    getStatusName(statusCode) {
      var res = statusCode;
      this.options.forEach(function(item) {
        if (statusCode === item.value) {
          res = item.label;
        }
      });

      return res;
    },
    async getOrders() {
      this.loadingOrders = true;

      try {
        const queryString = this.getFiltersAsQueryString();

        const { data } = await this.$axios.get(urls.urlGetOrders + queryString);

        this.currentPage = data.metadata.page_num;

        this.orders = [];

        data.orders.forEach(({
          Amount,
          PaymentType: PaymentType, // qr || link
          CreatedAt: CreatedAt,
          ExternalNumber: ExternalNumber,
          Status: Status,
          PaymentId: PaymentId,
          error_description: errorDescription,
          options,
          loadingStatus: loadingStatus,
          TargetSystem: TargetSystem,
          TransactionType: TransactionType,
          ManagerIdentifier: ManagerIdentifier,
        }) => {
          this.orders.push({
            Amount,
            PaymentType,
            CreatedAt,
            ExternalNumber,
            Status,
            PaymentId,
            errorDescription,
            options: true,
            loadingStatus: false,
            TargetSystem,
            TransactionType,
            ManagerIdentifier,
          });
        });

        this.pagesCount = data.metadata.total_pages;

        this.total.amount = data.metadata.total_amount;
        this.total.refund = data.metadata.total_refund;
        this.total.payment = data.metadata.total_payment;
        this.total.orders = data.metadata.total_orders;

        const url = `${this.$route.path}${queryString}`;

        window.history.pushState({ url }, '', url);
      } catch (e) {
        if (e.response) {
          let message = 'При загрузке операций возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику.';
          let title = 'Ошибка!';
          let route = 'admin.settings';
          if (e.response.data.message === 'Expired token') {
            message = 'Войдите в модуль заново из бэкофиса InSales.';
            title = 'Сессия завершена';
            route = 'admin.logout';
          } else if (e.response.data.message === 'Bad credentials.') {
            message = 'Для просмотра списка операций необходимо настроить связь с Kaspi.'
            title = 'Настройка не завершена';
          } else if (e.response.data.Message === 'Not configured') {
            message = 'Для просмотра списка операций необходимо выбрать торговую точку Kaspi.'
            title = 'Настройка не завершена';
          }
          await Swal.fire({
            icon: 'error',
            text: message,
            title: title,
          });
          await this.$router.push({name: route});
        }
      } finally {
        this.loadingOrders = false;
      }
    },
    checkReturnBtn(item) {
      if (item.TransactionType === 'payment' && item.Status === 'Processed') {
        return true
      } else {
        return false
      }
    },
    getColoredClass(classes, transactionType) {
      return transactionType !== 'payment' ? classes + ' refund-color' : classes;
    },

    getColoredStatusClass(classes, status) {
      let result = classes
      switch (status) {
        case 'QrTokenCreated':
          result = classes + ' badge-new-payment';
          break;
        case 'Wait':
          result = classes + ' badge-wait';
          break;
        case 'Processed':
          result = classes + ' badge-success-payment';
          break;
        case 'Error':
          result = classes + ' badge-error-payment';
          break;
        case 'QrTokenCreatedRefund':
          result = classes + ' badge-new-refund';
          break;
        case 'RefundProcessed':
          result = classes + ' badge-success-refund';
          break;
        case 'ErrorRefund':
          result = classes + ' badge-error-refund';
          break;
      }

      return result
    },

    async createRefund() {
      this.isProcessingRefund = true;
      try {
        const {data} = await this.$axios.post(urls.urlRefundCreate, {
          Data: {
            PaymentId: this.needRefundItem.PaymentId,
            Amount: this.needRefundItem.Amount.toString()
          }
        });

        if (data.StatusCode === 200) {
          // location.reload(); // чтобы не заморачиваться с интерактивностью
          this.showRefundModal = false
          await Swal.fire({
            icon: 'success',
            text: 'Возврат оформлен на сумму ' + this.getLocalizedAmount(this.needRefundItem.Amount) + ' ₸',
            title: 'Успех!',
          });
        } else {

          await Swal.fire({
            icon: 'error',
            text: data.Message,
            title: 'Ошибка!',
          });

        }
        await this.getOrders();

      } catch (e) {
        await Swal.fire({
          icon: 'error',
          text: e.response.data.Message,
          title: 'Ошибка!',
        });
      } finally {
        this.isProcessingRefund = false;
      }
    },
    showRefund(item) {
      this.needRefundItem.PaymentId = item.PaymentId;

      this.needRefundItem.Amount = item.Amount / 100;
      this.needRefundItem.OrderNumber = item.ExternalNumber;
      this.initialMaxRefundAmount = this.needRefundItem.Amount

      this.isValidationRefundError = false;
      this.showRefundModal = true;
    },
    validateRefundAmount() {

      this.isValidationRefundError = false;

      try {
        this.needRefundItem.Amount = parseFloat(this.needRefundItem.Amount.toString().replace(',', '.'))
        if (this.needRefundItem.Amount.toString() === 'NaN') {
          this.needRefundItem.Amount = 0
        }
        if (
          !isNumber(this.needRefundItem.Amount)
          || parseFloat(this.needRefundItem.Amount) < 0.01
          || parseFloat(this.initialMaxRefundAmount) < this.needRefundItem.Amount
        ) {
          this.isValidationRefundError = true;
          return false;
        }
      } catch (e) {
        this.isValidationRefundError = true;
        return false;
      }
      this.isValidationRefundError = false;

      return true
    },
    initFilters(clear = false) {
      let success_only = '';
      if (!clear) {
        success_only = 'yes';
      }
      this.filters = {
        created_at_from: '',
        created_at_to: '',
        external_number: '',
        transaction_type: '',
        status: '',
        success_only: success_only,
      };
    },
  },
};
</script>

<style scoped>

</style>
